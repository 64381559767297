//Libraries
import React from "react";

//CSS
import classes from "./ContactInfo.module.css";

const ContactInfo = (props) => {
	return (
		<div className={classes.Layout}>
			<div className={classes.InfoContainer}>
				<h2 className={classes.H2Tags}>
					Feel free to contact me at any time.
				</h2>
				<section className={classes.Info}>
					<h1 className={classes.H1Tag}>Calistro Vieyra</h1>
					<p className={classes.PText}>
						LinkedIn Profile:{" "}
						<a
							className={classes.AnchorTags}
							href="https://linkedin.com/in/calistrovieyra"
						>
							linkedin.com/in/calistrovieyra
						</a>
					</p>
					<p className={classes.PText}>
						Git hub link:{" "}
						<a
							className={classes.AnchorTags}
							href="https://github.com/vieyracalistro"
						>
							github.com/vieyracalistro
						</a>
					</p>
					<p className={classes.PText}>
						Portfolio link:{" "}
						<a
							className={classes.AnchorTags}
							href="https://calistrovieyra.com"
						>
							calistrovieyra.com
						</a>
					</p>
				</section>
			</div>
		</div>
	);
};

export default ContactInfo;
